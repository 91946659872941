
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  props: {},
  setup() {
    return {};
  },
});
