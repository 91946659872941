<template>
  <SiteMenu />
  <router-view />
  <Footer />
</template>

<script lang="ts">
import Footer from "@/components/Footer.vue";
import SiteMenu from "@/components/SiteMenu.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    SiteMenu,
    Footer,
  },
});
</script>
