<template>
  <div class="container">
    <div id="main">
      <section class="content">
        <Panel header="UmamuScript について" id="about">
          <p>Twitterで告知しておりましたように2023年6月5日を持ちましてUmamuScriptのサービス提供は終了致しました。</p>
          <p>2021年5月10日の公開から約2年が経過しましたが、その間多くの方にご協力頂き、サービスを継続することができました。<br />不具合のご連絡頂いた皆様、並びにご利用頂いていた皆様ありがとうございました。</p>
        </Panel>
      </section>

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  props: {},
  setup() {
    return {};
  },
});
</script>
