
import { defineComponent, ref, reactive, computed, SetupContext } from "vue";

export default defineComponent({
  name: "Footer",
  props: {},
  emits: [],
  setup(props, ctx: SetupContext) {
    return {};
  },
});
