<template>
  <Menubar :model="items">
    <template #start>
      <div class="logo">
        <img alt="logo" src="@/assets/logo-light.png" height="40" />
      </div>
    </template>
    <template #end>
      <div class="menu-end">
        <div class="menu-reload">
          <div class="reload-frame">
            <FontAwesomeIcon icon="redo" @click="reloadApplication" />
          </div>
        </div>
      </div>
    </template>
  </Menubar>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, SetupContext } from "vue";
import Menu from "primevue/menu";

export default defineComponent({
  name: "SiteMenu",
  props: {},
  emits: [],
  setup(props, ctx: SetupContext) {

    const items = ref([
      {
        label: "HOME",
        to: {
          name: "Home",
        },
      },
    ]);

    const reloadApplication = () => {
      window.location.reload();
    };

    return {
      reloadApplication,
      items,
    };
  },
});
</script>
