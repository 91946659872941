
import { defineComponent, reactive, ref, SetupContext } from "vue";
import Menu from "primevue/menu";

export default defineComponent({
  name: "SiteMenu",
  props: {},
  emits: [],
  setup(props, ctx: SetupContext) {

    const items = ref([
      {
        label: "HOME",
        to: {
          name: "Home",
        },
      },
    ]);

    const reloadApplication = () => {
      window.location.reload();
    };

    return {
      reloadApplication,
      items,
    };
  },
});
