<template>
  <footer>
    <p><small>&copy; 2021-2023 umastagram.com</small></p>
  </footer>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, computed, SetupContext } from "vue";

export default defineComponent({
  name: "Footer",
  props: {},
  emits: [],
  setup(props, ctx: SetupContext) {
    return {};
  },
});
</script>
