import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSync,
  faExclamationCircle,
  faAngleRight,
  faSpinner,
  faRedo,
  faSignInAlt,
  faSignOutAlt,
  faGripLines,
} from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(
  faSync, faExclamationCircle, faAngleRight,
  faSpinner, faRedo, faSignInAlt,
  faSignOutAlt, faTwitter, faClipboard,
  faGripLines
);

import VueLazyLoad from 'vue3-lazyload';

// PrimeVue
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/mdc-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
// prime flex
import 'primeflex/primeflex.css';

// PrimeVue
import Menubar from "primevue/menubar";
import Menu from 'primevue/menu';
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Panel from 'primevue/panel';
import Breadcrumb from "primevue/breadcrumb";

require("@/assets/scss/global.scss");

createApp(App as any)
  .use(store)
  .use(router)
  .use(VueLazyLoad, { log: false })
  .use(PrimeVue)
  .component("FontAwesomeIcon", FontAwesomeIcon)
  .component("Menubar", Menubar)
  .component("Menu", Menu)
  .component("TabView", TabView)
  .component("TabPanel", TabPanel)
  .component("Panel", Panel)
  .component("Breadcrumb", Breadcrumb)
  .mount("#app");
